.MuiOutlinedInput-adornedEnd {
  height: 40px;
  padding-right: 0px !important;
  width: 230px;
}
.calendar-container .MuiFormControl-root {
  width: 100%;
  height: 100%;
}
.calendar-container .MuiInputBase-root {
  width: 100%;
  height: 100%;
}

.calendar-container .MuiInputBase-root .MuiInputAdornment-positionEnd .MuiButtonBase-root {
  padding: 5px;
}
.MuiPickersBasePicker-pickerView {
  padding-bottom: 12px;
}

.calendar-item .MuiFormHelperText-contained {
  margin: 3px 0 0 10px;
  width: 300px !important;
}
.calendar-container .MuiFormHelp-root.Mui-error {
  font-size: 12px;
}
.preview-header .calendar-container input {
  font-size: 11px !important;
}

.calendar .calendar-container .calendar-item-1 .MuiFormHelperText-root.Mui-error {
  position: absolute;
  width: 300px;
  top: 1px !important;
  left: 300px;
  font-size: 12px;
}

.calendar .calendar-container .calendar-item-2 .MuiFormHelperText-root.Mui-error {
  position: absolute;
  width: 300px;
  top: 20px !important;
  left: 149px;
  font-size: 12px;
}

.calendar .calendar-container .calendar-item-single .MuiFormHelperText-root.Mui-error {
  position: absolute;
  width: 300px;
  top: 1px !important;
  left: 80px;
  font-size: 10px;
}

.MuiInputAdornment-positionEnd {
  margin-left: 0px !important;
}

.MuiPickersDay-current {
  color: unset !important;
}

.MuiPickersDay-daySelected {
  color: white !important;
}

.calendar-container {
  position: relative;
}

.weekInput-err {
  position: absolute;
  top: 25px;
  color: #f44336;
  margin: 3px 0 0 10px;
  width: 300px !important;
  font-size: 0.75rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.weekInput-err-hide {
  display: none;
}
.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: rgb(85, 130, 50) !important;
}
