.changePass-label {
  display: flex;

}

/* .changePass-labelEnd {
  padding: 2px 6px;
  margin-left: 10px;
  color: white;
  background-color: red;
  font-size: 13px;
  border: 1px solid red;
  border-radius: 5px;
  position: absolute;
  top: -2px;
  right: -60px;
} */
.icon-visibility{
  position: relative;
}
.backgorund-password-hover.MuiIconButton-edgeEnd{
  margin-right: 5px;
  padding:0px 5px
}
.backgorund-password-hover:hover.MuiIconButton-root{
  border-radius: 0%;
}
.changePass-labelEnd {
  /* padding: 0 0 30px 10px; */
  font-size: 20px;
  background-color: #fff;
  color: red;
  position: absolute;
  right: -20px;
  top: -2px;
}

.changePass-label .error-message {
  min-width: max-content;
  position: absolute;
  left: 50px;
  /* font-size: 10px; */
  line-height: 1.66;
  letter-spacing: 0.03333em;
  top: 10px;
  cursor: default;
}

.changePass-label .left140 {
  left: 50px;
}
