.form-compliance-wrapper {
  padding: 40px 20px 20px 15px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
  color: rgb(150, 150, 150);
}

.product-code-container,
.product-name-container,
.supplier-code-container,
.supplier-name-container,
.allergen-bf-container,
.allergen-af-container,
.tradeMark-container,
.confirm-container,
.approval-container {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  line-height: 1.1rem;
}

.product-code-title,
.product-name-title,
.supplier-code-title,
.supplier-name-title,
.allergen-bf-title,
.allergen-af-title,
.tradeMark-title,
.confirm-title,
.approval-title {
  width: 7.5%;
}

.product-code-content,
.product-name-content,
.supplier-code-content,
.supplier-name-content,
.allergen-bf-wrapper,
.allergen-af-wrapper,
.tradeMark-checkbox,
.confirm-checkbox,
.approval-wrapper {
  width: 92.5%;
  color: rgb(59, 56, 56);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-display {
  display: flex;
}

.allergen-bf-code {
  width: 100px;
  height: 25px;
  background-color: rgb(85, 130, 50);
  color: rgb(255, 255, 255);
  margin: 0px 2px 1px 0px;
}

.allergen-bf-code-no-check {
  width: 100px;
  height: 25px;
  background-color: rgb(191, 191, 191);
  color: rgb(255, 255, 255);
  margin: 0px 2px 1px 0px;
}

.allergen-af-code {
  width: 100px;
  height: 25px;
  background-color: rgb(255, 255, 102);
  color: rgb(59, 56, 56);
  margin: 0px 2px 1px 0px;
  border: 2px solid rgb(255, 217, 102);
  cursor: pointer;
}

.allergen-af-code-no-check {
  width: 100px;
  height: 25px;
  background-color: rgb(191, 191, 191);
  color: rgb(255, 255, 255);
  margin: 0px 2px 1px 0px;
  cursor: pointer;
}

.form-compliance-container .MuiRadio-colorSecondary.Mui-disabled {
  color: rgb(150, 150, 150);
}
.form-compliance-container .allergen-af-wrapper .MuiRadio-colorSecondary.Mui-checked {
  color: rgb(85, 130, 50);
}

.form-compliance-container .allergen-bf-radio .MuiIconButton-root,
.form-compliance-container .allergen-af-radio .MuiIconButton-root {
  padding: 0 10px 0 0;
}

.allergen-bf-title-text,
.allergen-af-title-text {
  line-height: 25px;
  display: flex;
  align-items: center;
}

.allergen-bf-row-1,
.allergen-af-row-1 {
  line-height: 25px;
  align-items: center;
}
.allergen-bf-row,
.allergen-af-row {
  line-height: 30px;
}

.allergen-bf-content,
.allergen-af-content {
  align-items: center;
}

.tradeMark-container,
.confirm-container {
  line-height: 40px;
  margin-bottom: 0;
}

.approval-container {
  line-height: 40px;
  margin-bottom: 0;
  min-width: 348px;
}
.tradeMark-container .MuiIconButton-root,
.confirm-container .MuiIconButton-root,
.approval-container .MuiIconButton-root {
  padding: 0;
}

.tradeMark-checkbox,
.confirm-checkbox,
.approval-checkbox,
.approval-wrapper,
.approval-date-container {
  display: flex;
  align-items: center;
}

.approval-date-title {
  margin-right: 30px;
}

.allergen-af-title-text {
  margin-left: 58px;
}

.allergen-af-title-text,
.tradeMark-title,
.confirm-title,
.approval-title,
.approval-date-title {
  color: rgb(85, 130, 50);
}

.approval-checkbox {
  width: 80px;
}

.allergen-af-wrapper .MuiSvgIcon-root path {
  color: rgb(85, 130, 50);
}

.buttonSpace {
  margin-top: 50px;
  min-width: 298px;
}
/* .form-compliance-wrapper .footerButtonGroup .button {
  width: 130px !important;
  height: 30px !important;
} */
.button {
  width: 128px;
  height: 28px;
  color: rgb(85, 130, 50);
  border-color: rgb(85, 130, 50) !important;
  margin: 0 20px 0 0 !important;
}

.button:hover {
  opacity: 0.9;
}

.form-compliance-wrapper .submit-button {
  background-color: rgb(85, 130, 50) !important;
  color: #fff !important;
  font-size: 13px;
  width: 128px;
  height: 28px;
}
.form-compliance-wrapper .button.clear-button {
  color: rgb(59, 56, 56);
  width: 128px;
  height: 28px;
}

.form-compliance-wrapper .submit-button:disabled {
  background-color: rgb(187, 187, 187) !important;
}

.form-compliance-wrapper .buttonSpace .MuiButton-label {
  font-size: 13px;
}

.allergen-bf-code,
.allergen-bf-code-no-check,
.allergen-af-code,
.allergen-af-code-no-check {
  width: 65px;
  font-size: 11px;
}

.allergenContainer {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  line-height: 1.1rem;
}

.allergenContainer .title {
  /* width: 13%; */
  min-width: 127px;
}

.allergenContainer .content {
  width: 89%;
}

.content__row1 {
  display: flex;
}

.radio {
  color: rgb(59, 56, 56);
}

.radio__1 {
  margin-bottom: 100px;
}

.content__row1,
.content__row2,
.content__row3,
.content__row4 {
  display: flex;
}

.content__row2,
.content__row3,
.content__row4 {
  line-height: 30px;
  min-width: 122px;
}

.form-compliance-wrapper .MuiRadio-colorSecondary.Mui-disabled {
  color: rgb(150, 150, 150);
}

.form-compliance-wrapper .radio__container .MuiIconButton-root,
.form-compliance-wrapper .radio__container .MuiIconButton-root {
  padding: 0 10px 0 0;
}

.allergen-bf-content {
  color: rgb(59, 56, 56);
}

.form-compliance-wrapper .allergenAfter .MuiRadio-colorSecondary.Mui-checked,
.allergenAfter .title__Text {
  color: rgb(85, 130, 50);
}

.Mui-focusVisible :hover {
  background-color: red;
}

.allergenAfter .title__Text {
  margin-left: 58px;
}

.fontWeightBold {
  font-weight: bold;
}

.fontWeightNormal {
  font-weight: normal;
}

@media (max-width: 1900px) {
  .form-compliance-container {
    min-width: max-content;
  }
  .form-compliance-container .title-common {
    width: 11%;
  }
  .form-compliance-container .content-common {
    width: 89%;
  }
  .allergen-bf-container {
    margin-right: 20px;
  }

  .form-compliance-wrapper .title-common {
    min-width: 127px;
  }
  .form-compliance-wrapper .content-common {
    width: 89%;
  }
}

/* @media (max-width: 1370px) {
  .form-compliance-wrapper {
    overflow-x: scroll;
  }
} */
