.wrapper__fpwd {
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
}

.wrapper__fpwd .wrapper__fpwd-title {
  text-align: center;
  padding: 20px 0;
  border-bottom: 2px solid #e6e7e6;
  color: rgb(85, 130, 55);
  font-weight: 600;
  font-size: 20px;
}

.wrapper__fpwd .wrapper__fpwd-form {
  max-width: 480px;
  margin: auto;
}

.wrapper__fpwd-form .wrapper__fpwd-helptext {
  margin: 40px 0;
  display: flex;
  font-size: 14px;
}

.wrapper__fpwd-form .wrapper__fpwd-helptext .section-note {
  margin-right: 10px;
}

.wrapper__fpwd-form .wrap {
  margin: 10px 0;
}

.wrapper__fpwd-form .wrap .wrap-icon {
  margin-right: 10px;
}

.wrapper__fpwd-form .wrap .wrap-icon svg {
  color: rgb(150, 150, 150);
}

.wrapper__fpwd-form .wrap input {
  font-size: 16px;
  color: rgb(59, 56, 56);
}

.wrapper__fpwd-form .wrap .MuiInputLabel-formControl {
  color: rgb(85, 130, 55);
  font-size: 12px;
}

.wrapper__fpwd-form .wrap .MuiFormLabel-root.Mui-focused {
  color: rgb(85, 130, 55) !important;
}

.wrapper__fpwd-form .wrap .MuiInput-underline::after {
  border-bottom: rgb(85, 130, 55) !important;
}

.wrapper__fpwd-form .wrap label {
  color: rgb(85, 130, 55) !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom-color: rgb(85, 130, 55) !important;
}

.wrapper__fpwd-form .wrap .MuiFormLabel-asterisk {
  color: red;
}

.form-forgot__button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: 70px 0;
}

.form-forgot__button .btn-submit-container .btn-submit {
  width: 150px;
  height: 30px;
  max-width: 150px;
  font-size: 13px;
  color: rgb(255, 255, 255);
  background-color: rgb(85, 130, 55);
  border-color: rgb(85, 130, 55);
  border-radius: 4px;
}

.form-forgot__button .btn-cancel-container .btn-cancel {
  background-color: rgb(255, 255, 255);
  color: rgb(59, 56, 56);
  border: 1px solid rgb(85, 130, 55);
  margin: 0 20px;
  width: 150px;
  height: 30px;
  font-size: 13px;
}

.form-forgot__button .btn-submit-container .btn-submit:hover {
  opacity: 0.9;
  color: rgb(255, 255, 255);
  background-color: rgb(85, 130, 55);
  border: 1px solid rgb(85, 130, 55);
}
.form-forgot__button .btn-cancel-container .btn-cancel:hover {
  opacity: 0.9;
  color: rgb(85, 130, 55);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(85, 130, 55);

}

.icon-deactive {
  width: 24px;
  height: 24px;
}
.forgot-password-helper-text {
  font-size: 13px;
}

.btn-submit-text {
  color: rgb(255, 255, 255);
}

.form-forgot__button .MuiButton-root.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.26) !important;
}

.form-forgot__button .btn-submit-container {
  cursor: pointer;
  font-size: 13px;
}

@media screen and (max-width: 760px) {
  .wrapper__fpwd .wrapper__fpwd-form {
    padding: 0 20px;
    width: 100%;
  }
  .wrapper__fpwd-form .wrapper__fpwd-helptext {
    font-size: 10px;
  }
}
