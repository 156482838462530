.form-container,
.search-item .form-container .MuiFormControl-root,
.search-item .form-container .MuiFormControl-root .MuiInputBase-root {
  width: 100%;
  height: 100%;
  width: fit-content;
}

.form-container .MuiFormControl-root .MuiInputBase-root input {
  min-width: 242px;
  height: 100%;
  padding: 0 14px;
  font-size: 12px;
}

.MuiInputLabel-outlined {
  transform: translate(12px, 8px) scale(0.75) !important;
  color: rgb(85, 130, 50) !important;
}
