.wrap {
  display: flex;
  align-items: center;
}

.wrap-icon {
  width: 30px;
  position: relative;
  top: 9px;
  margin-right: 5px;
}

.wrap-icon svg {
  font-size: 1.7rem;
  color: rgb(85, 130, 55);
}

.wrap .MuiFormControl-root {
  display: table-cell;
  width: 100%;
}
.wrap .MuiFormControl-root .MuiInput-formControl {
  width: 100%;
}
.wrapper__fpwd-form .wrap .MuiFormControl-root .MuiInput-formControl {
  width: 320px;
}
.short-text {
  overflow: hidden;
  text-overflow: clip;
}
label#standard-basic-label {
  padding-left: 5px;
}
