#modal__confirm .MuiPaper-root {
  font-size: 16px;
  border-radius: 2px;
  border: 2px solid rgb(85, 130, 50);
  width: 403px;
}

#modal__confirm .dialog__message-content {
  display: flex;
  /* align-items: center; */
  margin: 20px 20px 0px 15px;
  padding: 5px 0px 0px 0px;
  color: rgb(85, 130, 50);
  font-size: 14px;
  overflow-y: hidden;
}

#modal__confirm .dialog__message-content div {
  font-size: 12px;
}

#modal__confirm .dialog__message-content p {
  color: rgb(85, 130, 50);
  /* font-size: 14px; */
}
#modal__confirm .dialog__message-title {
  margin: 0;
  padding: 0;
  background: rgb(85, 130, 50);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 0px 15px;
}
#modal__confirm .dialog__message-title h2 {
  /* font-size: 18px; */
  margin: 5px 0;
  font-size: 13px;
}

#modal__confirm .MuiButton-label {
  font-size: 13px !important;
}

#modal__confirm .MuiDialogActions-root button {
  width: 128px;
  height: 28px;
  margin: 0 10px;
  font-size: 13px;
}
#modal__confirm .dialog__message-action {
  padding: 15px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal__confirm .button-submit {
  background-color: rgb(85, 130, 55) !important;
  border-color: 1px solid rgb(85, 130, 55) !important;
  font-size: 13px;
}

.message__pre-text {
  text-align: left !important;
  font-size: 13px !important;
}

#modal__confirm .button-cancel {
  color: rgb(59, 56, 56) !important;
  border: 1px solid rgb(85, 130, 50) !important;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  font-size: 13px;
}

#modal__confirm .MuiDialog-root .MuiDialog-paperWidthFalse {
  max-width: 350px !important;
}

#confirm-dialog-title {
  color: rgb(255, 255, 255);
  background-color: rgb(85, 130, 55) !important;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

#modal__confirm .MuiDialog-container .MuiDialog-paper {
  margin: 0;
  padding: 0;
  width: 500px;
  height: 170px;
}

#modal__confirm .wrapper__opacity {
  padding: 0;
  margin: 0;
}

.message__pre-text {
  text-align: left !important;
  font-size: 12px !important;
}

.alignEnd {
  justify-content: end !important;
}
