.wrapper_select_input .MuiTextField-root .MuiInputLabel-formControl.Mui-required::after {
  top: -5px;
  left: calc(100% + 5px);
  color: red;
  width: max-content;
  margin: 3px;
  content: '*';
  position: absolute;
  font-size: 20px;
  background: #fff;
  margin-right: 10px;
}
.wrapper_select_input
  .MuiTextField-root
  .MuiInputLabel-formControl.Mui-required
  .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
  display: none;
}

.wrapper_select_input #label {
  position: absolute;
  left: 38px;
}
.wrapper_select_input #label.require_label::after {
  top: -5px;
  left: calc(100% + 5px);
  color: red;
  width: max-content;
  margin: 3px;
  content: '*';
  position: absolute;
  font-size: 20px;
  background: #fff;
  margin-right: 10px;
}

.wrapper_select_input .label_unshink.MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(1) !important;
  top: 22px;
}
.wrapper_select_input .MuiSvgIcon-root.MuiSelect-icon {
  color: rgb(85, 130, 50);
  top: calc(50% - 17px);
  font-size: 32px;
  right: 0;
  transform: scaleY(1.5);
}
.wrapper_select_input .MuiOutlinedInput-root {
  position: relative;
  border-radius: 4px;
  height: 25px;
  font-size: 12px;
  color: rgb(85, 130, 50);
  width: 95px;
}
.wrapper_select_input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgb(85, 130, 50);
}
.wrapper_select_input .MuiOutlinedInput-notchedOutline {
  border-color: rgba(80, 80, 80, 0.9);
}

.select-item .select-container .MuiFormControl-root,
.select-item .select-container .MuiInputBase-root,
.sorted-select .sorted-container .MuiFormControl-root,
.sorted-select .sorted-container .MuiInputBase-root {
  width: 100%;
  height: 100%;
}
.select-item .select-container .MuiSelect-root,
.sorted-select .sorted-container .MuiSelect-root {
  width: 100%;
  color: rgb(85, 130, 50);
}

.select-item .select-container .MuiOutlinedInput-input,
.sorted-select .sorted-container .MuiOutlinedInput-input {
  padding-top: 0px;
  padding-bottom: 0px;
}

.maintain-wrapper .select-item .Mui-focused .MuiSelect-select {
  background: transparent;
}
.MuiMenuItem-root {
  font-size: 12px !important;
}
