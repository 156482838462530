#Modal__preview-file .MuiBox-root {
  width: 80%;
  height: 80vh;
}
#Modal__preview-file .MuiBox-root .pg-viewer-wrapper {
  overflow: scroll !important;
}
#Modal__preview-file .MuiBox-root .react-grid-Main {
  overflow: scroll !important;
}

/* diable preview file excel */
#Modal__preview-file .spreadsheet-viewer .react-grid-HeaderCell-sortable {
  pointer-events: none !important;
}
.iframe--previewFile__txt .background-file {
  height: calc(80vh - 100px);
  min-width: 100%;
  border: none;
  outline: 0;
  overflow: auto;
  white-space: pre;
  background: rgba(1,1,1,0.02);
  font-size: 14px;
}
.iframe--previewFile__txt div:first-child{
  padding-bottom: 15px;
}
.iframe--previewFile__txt br{
  content : ""
}