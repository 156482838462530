.myPage_container {
  width: 100%;
}

.myPage_wrapper {
  padding: 16px 20px 0 20px;
}

.userInfo_form_container {
  height: 100%;
  padding: 5px 40px 30px 40px;
  max-width: 1200px;
}
.userInfo_form_container:first-of-type {
  padding: 5px 40px 0px 40px;
}

.userInfo_header_text,
.change_userPass_header_text {
  color: rgb(85, 130, 50);
  padding: 5px 0px 5px 15px;
  font-size: 13px;
  border-bottom: none;
}

.userInfo_header,
.change_userPass_header {
  border-bottom: hidden;
  background-color: rgb(245, 250, 235);
}

.nm-user-container {
  display: flex;
}

.userInfo_form_container .changepass-container {
  padding: 0;
}

.changepass-container .wrap-lock-icon {
  padding: 0 15px 0 0 !important;
}
.MuiIconButton-edgeEnd {
  margin-right: 0px;
}
.customer-id,
.customer-name {
  font-size: 12px;
  line-height: 12px;
}
.changepass-container .MuiInputBase-input {
  padding: 5px 0px 2px 10px;
  font-size: 13px;
}

.userInfo_header_text_wrapper,
.change_userPass_header_text_wrapper {
  min-width: max-content;
}

.userInfo_form_container .MuiGrid-item {
  /* padding: 10px 15px 0px 0px !important;1231312312 */
}

.userInfo_form_container .content {
  height: 40px;
  position: relative;
}

.myPage_wrapper .nm-user-container .MuiCheckbox-colorSecondary.Mui-disabled,
.myPage_wrapper .nm-user-container .MuiCheckbox-colorSecondary.Mui-checked,
.myPage_wrapper .nm-user-container .custom-checkbox .Mui-checked .MuiSvgIcon-root {
  color: grey !important;
  padding-left: unset;
}

.portal-create-content {
  height: 100%;
}

.form-min-width {
  min-width: 500px;
}

.formEdit-container .wrap {
  padding: 0px !important;
}

.formEdit-container .wrap-icon {
  width: unset;
  position: relative;
  top: 4px;
  margin: 0;
  padding: 10px 15px 0px 0px;
}

.formEdit-container .icon--deactive {
  color: rgb(150, 150, 150) !important;
}

.iconForm {
  width: 24px;
  height: 24px;
}

.iconForm-nutel {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.formEdit-container .width450 {
  width: 450px;
}
.formEdit-container .width450 label {
  font-size: 12px;
}

.portal-create .MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(0.8333334) !important;
  transform-origin: 0px 40px;
}

.portal-create .MuiInputLabel-formControl {
  font-size: 13px;
}

.formEdit-container .wrap label + .MuiInput-formControl {
  margin-top: 18.76px;
}

.portal-create-content .MuiInput-root {
  font-size: 10px;
}

.formEdit-container .wrap .MuiFormControl-root .MuiInput-formControl .MuiInput-input {
  padding: 5px 0px 2px 10px;
  font-size: 12px;
}

.formEdit-container .wrap-icon svg {
  font-size: 1.5rem !important;
}

.myPage_wrapper .MuiInput-underline:after {
  border-color: rgb(85, 130, 55) !important;
}

.myPage_wrapper .MuiFormLabel-root {
  color: rgb(85, 130, 50) !important;
}

.portal-create .MuiInput-underline:before {
  border-bottom: 1px solid rgb(85, 130, 50) !important;
}

.formEdit-container .formEdit-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.formEdit-container {
  width: 100%;
}

.formEdit-container .formEdit-input .input-spacing {
  width: 40px;
}

.formEdit-container .formEdit-input .wrap .wrap-icon {
  padding: 0;
}
.formEdit-container .formEdit-input .wrap .MuiFormControl-root {
  margin-right: 44px;
}

.changepass-container .MuiFormControl-root {
  height: 100%;
}

.formEdit-container .width120 {
  width: 120px;
}

.formEdit-container .formEdit-input .MuiFormLabel-root.Mui-disabled {
  color: rgba(150, 150, 150) !important;
}

.formEdit-container .width210 {
  width: 210px;
}
.portal-create .MuiTextField-root .MuiInputBase-root .MuiInputBase-input.Mui-disabled {
  color: rgb(150, 150, 150);
}

.formEdit-container .width160 {
  width: 160px;
}
.customer-container {
  display: flex;
  height: 40px;
}

.customer-content {
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  margin-bottom: 5px;
}

.customer-id {
  margin: 0 10px;
}

.portal-create .formEdit-input .MuiInput-underline:before {
  border-bottom: 1px solid rgb(150, 150, 150) !important;
}
.btn-submit,
.btn-cancel {
  font-size: 13px;
}
