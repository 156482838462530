.delivery-data__wrapper {
  width: 100%;
  display: block;
  /* overflow: auto; */
}

.delivery-data__wrapper .delivery-data__container {
  margin: 0 20px 20px 15px;
}

.delivery-data__container .calendar-container {
  width: 105px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
}
.spacing {
  height: 5px;
}
.pb-25 {
  margin-bottom: 25px;
}

.list-table-container {
  min-width: 1050px;
}

.delivery-data-detail__wrapper .toolbar-no-height,
.delivery-data__container .toolbar-no-height {
  height: 60px;
}

@media (max-width: 1500px) {
  .delivery-data__wrapper .delivery-data__container .table-scroll {
    overflow-x: auto;
    width: 100%;
  }
}
