.container-common {
  margin: 0 20px 20px 15px;
}

.preview-filter .calendar-container,
.container-common .calendar-container {
  width: 105px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
}

.MuiButton-containedPrimary {
  background-color: rgb(85, 130, 50) !important;
}
