.info-container {
  position: relative;
  width: 800px;
  height: 20px;
}

.icon-container:hover + .infoText-container {
  display: block;
}
.icon-container:hover + .multi-infoText-container {
  display: block;
}

.icon-container:hover + .infoText-container {
  display: block;
}

.infoText-container {
  display: none;
  position: absolute;
  top: 20px;
  left: 20px;
}

.infoText {
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  background-color: #fff;
  color: rgb(85, 130, 50);
  padding: 10px;
  border-radius: 10px;
  border: 1.75px solid rgb(85, 130, 50);
}

.infoText:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 7px 20px 7px 0px;
  top: 50%;
  left: -17px;
  border-color: transparent white;
  display: block;
  width: 0;
  z-index: 1;
}

.infoText:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 7px 22px 7px 0px;
  top: 50%;
  left: -21px;
  border-color: transparent rgb(85, 130, 50);
  display: block;
  width: 0;
  z-index: 0;
}

.mutiple-line .infoText {
  width: fit-content;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.mutiple-line .infoText-container {
  top: -26px;
}

/* //custom infor with mypage */
/* custom-background-infor */

.custom-background-infor .info-container {
  position: relative;
  width: 800px;
  height: 20px;
}

.custom-background-infor .icon-container:hover + .infoText-container {
  display: block;
}
.custom-background-infor .icon-container:hover + .multi-infoText-container {
  display: block;
}

.custom-background-infor .infoText-container {
  display: none;
  position: absolute;
  top: -18px;
  left: 40px;
}

.custom-background-infor .multi-infoText-container {
  display: none;
  position: absolute;
  top: -30px;
  left: 40px;
}

.custom-background-infor .infoText {
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  background-color: #fff;
  color: rgb(85, 130, 50);
  padding: 10px;
  border-radius: 10px;
  border: 1.75px solid rgb(85, 130, 50);
  top: unset;
  left: unset;
}

.custom-background-infor .infoText-container:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 7px 20px 7px 0px;
  top: 50%;
  left: -17px;
  border-color: transparent white;
  display: block;
  width: 0;
  z-index: 1;
}

.custom-background-infor .infoText-container:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 7px 22px 7px 0px;
  top: 50%;
  left: -21px;
  border-color: transparent rgb(85, 130, 50);
  display: block;
  width: 0;
  z-index: 0;
}

.custom-background-infor .mutiple-line .infoText {
  width: fit-content;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.custom-background-infor .mutiple-line .infoText-container {
  top: -26px;
}

.custom-background-infor .mutiple-line .multi-infoText-container {
  top: -26px;
}
