/* Portal, Head-Quarter, Distribution Data responsive */
@media (max-width: 1290px) {
  .maintain-wrapper .table-scroll,
  .hquser__wrapper .table-scroll,
  .distribution__wrapper .table-scroll {
    overflow-x: auto;
    width: 100%;
  }
}

/* Branch, Customer, Allergen responsive */

@media (max-width: 1100px) {
  .branch__wrapper .table-scroll,
  .customer__wrapper .table-scroll,
  .allergen__wrapper .table-scroll,
  .excel-template__wrapper .table-scroll,
  .reception-data__wrapper .table-scroll {
    overflow-x: auto;
    width: 100%;
  }
}

@media (max-width: 1900px) {
  .delivery-data-detail__wrapper .table-scroll {
    overflow-x: auto !important;
    padding-bottom: 25px;
    width: 100%;
  }
}

.delivery-data-detail__wrapper .delivery-data-detail__container .table-container .MuiTableContainer-root {
  overflow-x: hidden;
}

.table-container-fit-content {
  width: fit-content;
}
.table-container-fit-content
  .MuiGrid-root
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root {
  border-bottom: none;
}
