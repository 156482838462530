.info-container {
  position: relative;
  width: 800px;
  height: 20px;
}

.icon-container:hover + .infoText-container {
  display: block;
}

.infoText-container {
  display: none;
  position: absolute;
  top: 17px;
  left: 10px;
}

.triangle {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 2px 20px 32px 5px;
  top: -2px;
  left: 0px;
  border-color: transparent transparent rgb(85, 130, 50) transparent;
  display: block;
  width: 0;
  z-index: 0;
}

.triangle-hiden {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 3px 20px 32px 4px;
  top: -2px;
  left: 1px;
  border-color: transparent transparent white transparent;
  display: block;
  width: 0;
  z-index: 1;
}

.infoText {
  position: relative;
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  background-color: #fff;
  color: rgb(85, 130, 50);
  padding: 10px;
  border-radius: 10px;
  border: 1.75px solid rgb(85, 130, 50);
  top: 20px;
  left: -20px;
}

.mutiple-line .infoText {
  width: fit-content;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.mutiple-line .infoText-container {
  top: -26px;
}
