.delivery-data-detail__wrapper {
  width: 100%;
  display: block;
  /* overflow: auto; */
}
.delivery-data-detail__container {
  margin: 0 20px 20px 15px;
}
.delivery-data-detail__container .calendar-container {
  width: 105px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
}
.spacing {
  height: 5px;
}

.pb-25 {
  margin-bottom: 25px;
}

.table-list-container {
  min-width: 1050px;
}

@media (max-width: 1366px) {
  .delivery-data-detail__wrapper .delivery-data-detail__container .table-scroll {
    overflow-x: auto !important;
    padding-bottom: 25px;
    width: 100%;
  }
}

.delivery-data-detail__wrapper .delivery-data-detail__container .table-container .MuiTableContainer-root {
  overflow-x: hidden;
}
