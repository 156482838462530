.login {
  background: white;
  height: 100vh;
  overflow: hidden;
}

.login .login-wrap {
  height: 100%;
}

.login .login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.login-form {
  width: 30%;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
  width: 370px;
  height: 215px;
}

.login-form .card {
  margin-bottom: 0 !important;
}

.login-form .card .header {
  padding: 0;
}

.login-form .card .content {
  padding: 0px 30px 15px 30px;
}

.login-form .header {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: rgb(230, 250, 200) !important;
  border-color: rgb(230, 250, 200) !important;
  height: 45px;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.login-form .header h4 {
  color: rgb(85, 130, 55);
  font-size: 12px;
  overflow: hidden;
  font-weight: 800;
  padding-bottom: 5px;
}

.login-form .wrap {
  margin: 5px 0;
  height: 46px;
}

.login-form .wrap .MuiInput-formControl {
  margin-top: 12px;
}

.login-form .wrap-icon {
  padding-top: 10px;
}

.login-form .content input {
  font-size: 12px;
  color: rgb(40, 40, 40);
}

.login-button {
  margin: 3px 0px 0px 0px;
}

.login-button button {
  width: 100%;
  height: 23px;
  color: rgb(255, 255, 255);
  background-color: rgb(85, 130, 55) !important;
  border-color: rgb(85, 130, 55) !important;
  font-size: 13px;
}

.login-button button:hover {
  opacity: 0.9;
}

.login-forget-password {
  margin-top: 13px;
  text-align: right;
  font-size: 9px;
  color: rgb(59, 56, 56);
}

.btn-login .MuiButton-label {
  font-size: 12px;
}

.login footer {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
}

.login-form .card .content .wrap .MuiFormLabel-root.Mui-focused {
  color: rgb(85, 130, 55) !important;
}

.login-form .card .content .wrap .MuiInput-underline::after {
  border-bottom: 2px solid rgb(85, 130, 55) !important;
}

.login-form .card .content .wrap label {
  color: rgb(85, 130, 55) !important;
  font-size: 12px;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom-color: rgb(85, 130, 55) !important;
}

.login-form .MuiInputBase-root input {
  padding-left: 10px;
}
.login-form .wrap-icon .MuiSvgIcon-root {
  color: rgb(150, 150, 150);
  margin-bottom: 2px;
}

.MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(0.8333334) !important;
}

@media (max-width: 768px) {
  .login-form {
    width: 90%;
  }

  .login-form .header h4 {
    font-size: 13px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .login-form {
    width: 50%;
  }
  .login-form .header h4 {
    font-size: 13px;
  }
}

@media (min-width: 1025px) {
  .login-form .header h4 {
    font-size: 13px;
  }
}
