.toolbar {
  height: 60px;
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 1050px;
}
.toolbar-no-height {
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 1050px;
}
.toolbar-no-height-padding-top {
  /* margin: 5px 0px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 1050px;
  padding-top: 15px;
}

.toolbar-small {
  min-width: 900px;
}

.actionContainer {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  /* margin-bottom: 5px; */
}

.csv-container {
  width: 20%;
  height: 25px;
  /* margin-bottom: 5px; */
  /* align-items: center;
    margin-bottom: 5px; */
}

.csv-style {
  margin-right: 30px;
}

.searchContainer {
  height: 100%;
  width: 270px;
  margin-right: 50px;
}

.searchLeftContainer {
  height: 100%;
  width: 270px;
  margin-left: 50px;
}

.search-item {
  width: 100%;
  height: 100%;
}

.icon-redirect {
  width: 23px;
  height: 23px;
  cursor: pointer;
  margin-right: 15px;
}

.icon-action {
  width: 16px;
  height: 18px;
  cursor: pointer;
}

.previewContainer,
.createContainer,
.filterIconContainer,
.expireDateContainer,
.filterWeekContainer,
.turnBackIconContainer,
.timeLabelContainer {
  width: fit-content;
  height: 100%;
}

.icon-filter {
  width: 16px;
  height: 18px;
  margin-right: 15px;
  /* margin-left: 5px; */
}

.filterWeekContainer {
  margin-left: 20px;
}

.filterIconContainer,
.filterWeekContainer,
.downloadIconContainer,
.checkboxContainer,
.expireDateContainer,
.timeLabelContainer {
  display: flex;
  align-items: center;
}

.turnBackIconContainer {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.toolbar-text {
  font-size: 12px;
  color: rgb(166, 166, 166);
  margin-right: 10px;
}

.timelablel-text {
  font-size: 10px;
  color: rgb(166, 166, 166);
  margin-right: 10px;
  margin-left: 10px;
}

.select-item {
  height: 100%;
  width: 90px;
  margin-right: 20px;
}

.filterContainer {
  /* width: 80%; */
  display: flex;
  height: 25px;
}

.year-month-calendar-container {
  display: flex;
  flex-direction: row;
  height: 25px;
  align-items: center;
}

.dt-week-calendar-container {
  display: flex;
  flex-direction: row;
  height: 25px;
  align-items: center;
  margin-right: 15px;
}

.week-calendar-item {
  /* margin-left: 2px; */
  margin-right: 15px;
}
.complianceCalendarFilter {
  display: flex;
  height: 25px;
  margin-right: 20px;
}

.complianceCalendarFilter {
  display: flex;
  height: 25px;
  margin-right: 20px;
}

.downloadIconContainer {
  margin-right: 15px;
  width: fit-content;
  height: 100%;
}
.checkboxContainer {
  margin-right: 15px;
}
.calendar-item {
  width: 95px;
  height: 100%;
  margin-right: 10px;
}

.icon-turnback {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.calendar-item input {
  font-size: 12px;
  color: black;
}

.btn-download {
  height: 25px;
  display: flex;
  position: relative;
  margin-bottom: 15px;
  color: white;
  min-width: 170px;
  justify-content: flex-end;
  font-size: 13px;
  /* background-color: rgb(85, 130, 50); */
}

.btn-text {
  color: white;
  text-decoration: none;
  font-size: 13px;
}

.btn-download .MuiButton-root {
  min-width: 170px !important;
  font-size: 13px;
}
.toolbar .calendar-item .MuiInputBase-root .MuiInputBase-input {
  font-size: 11px !important;
}

.toolbar .MuiSelect-select:focus {
  background-color: transparent;
}

.toolbar-no-height .MuiSelect-select:focus {
  background-color: transparent;
}

.approvalContainer,
.hqConfirmContainer {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.hqConfirm-label,
.apporval-label,
.filterWeek-label {
  width: max-content;
}

.compliance__wrapper .searchContainer {
  margin-right: 11px;
}

.arrow-back-icon {
  color: rgb(85, 130, 50);
}

.choose-all-container {
  height: 25px;
  width: 140px;
  margin-right: 10px;
}
.choose-all-container .choose-all {
  height: 25px;
  background-color: rgb(255, 255, 255);
  color: rgb(85, 130, 50);
  border: 1.9px solid rgb(85, 130, 50);
  font-size: 13px;
  min-width: 120px;
}
.btn-download-all-container {
  height: 25px;
  width: 170px;
  /* border: rgb(85, 130, 50) 3px; */
}
.btn-download-all-container .btn-download-all {
  height: 25px;
  background-color: rgb(85, 130, 50);
  color: rgb(255, 255, 255);
  font-size: 13px;
  min-width: 140px;
}

.btn-download-all-container .btn-download-all :hover {
  height: 25px;
  background-color: rgb(85, 130, 50);
  color: rgb(255, 255, 255);
  font-size: 13px;
}
.MuiButton-contained:hover {
  background-color: rgb(85, 130, 50);
}
.btn-download-all-container :hover {
  background-color: rgb(85, 130, 50) !important;
}

.infomation-container {
  width: 14%;
  margin-right: 25px;
  margin-left: 10px;
}

.toolbar-to-lbl {
  font-size: 12px;
  color: rgb(166, 166, 166);
  margin: auto 10px;
}

@media (max-width: 1570px) {
  .maintain-container .filterIconContainer .toolbar-text,
  .expireDateContainer .toolbar-text,
  .timeLabelContainer .timelablel-text {
    min-width: 50px;
  }
}
