.compliance__wrapper {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.compliance-scroll {
  /* overflow-y: auto; */
}

.compliance__container {
  margin: 0 !important;
}

.table-container .MuiTableCell-root {
  border-bottom: none;
}

.table-compliance-padding {
  padding: 0 20px 20px 15px;
  overflow-y: auto;
}

.supplier-container {
  min-width: 100%;
  display: flex;
  padding-top: 8px;
  padding-bottom: 2px;
  border-bottom: 0;
}

.supplier-header {
  font-size: 15px;
}

.supplier-code {
  width: 35%;
  margin-left: 10px;
}

.supplier-groupTitle {
  color: rgb(85, 130, 50);
  margin-right: 10px;
}

.supplier-name {
  width: calc(100% - 12.5%);
}

.supplier-groupTitle-size {
  white-space: nowrap;
  resize: none;
  overflow: hidden;
}

.compliance-record .MuiTableCell-body {
  padding: 0px;
}

.compliance-cell {
  height: 35px;
  display: flex;
  align-items: center;
  font-size: 11px;
}

.compliance-cell-row-1 {
  height: 30px;
}

.compliance-cell-border {
  border-bottom: 1px solid grey;
}
.paddinglr-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.paddingr-20 {
  padding-right: 20px !important;
}

.paddingr-40 {
  padding-right: 40px !important;
}

.paddingl-16 {
  padding-left: 16px;
}

.paddingl-20 {
  padding-left: 20px;
}

.paddingb-2 {
  padding-bottom: 2px;
}

.text-red {
  color: red;
}

.alignItemEnd {
  align-items: flex-end;
}

.alignItemCenter {
  align-items: center;
}

.paddingb-5 {
  padding-bottom: 5px;
}

.radio-checkbox-2 {
  justify-content: center;
}

.radio-checkbox .MuiRadio-root {
  padding: 0 !important;
  cursor: auto;
}

.radio-checkbox .MuiSvgIcon-fontSizeSmall {
  font-size: 0.9rem;
}

/* .compliance__wrapper .right-radio .MuiButtonBase-root {
  margin-left: 10px;
} */

.compliance__wrapper .radio-checkbox .MuiSvgIcon-root {
  width: 15px;
  height: 15px;
}

.flex {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.flexEnd {
  justify-content: flex-end;
}

.background-blue {
  background-color: #f5faeb;
}
.table-compliance-padding {
  height: 100vh;
}

.compliance-update {
  justify-content: center;
}

.compliance-content-scroll {
  overflow-x: auto;
  width: 100%;
}

.compliance__wrapper .toolbar .calendar-item .MuiInputBase-root .MuiInputBase-input {
  font-size: 12px !important;
}

.compliance__wrapper .MuiButton-root {
  background-color: rgb(85, 130, 50);
  color: white;
}

.compliance__wrapper .MuiButton-contained:hover {
  background-color: rgb(85, 130, 50) !important;
}

.compliance__wrapper .btn-download .MuiButton-label {
  font-size: 13px;
}

.header-height .MuiTableCell-head {
  line-height: 1rem;
}

.header-height .MuiTableCell-root {
  padding: 5px;
}
@media (max-width: 1653px) {
  /* .compliance__wrapper .header-col .col-3-small-screen {
    width: 40% !important;
  } */
  .compliance__wrapper .header-col .col-6-small-screen {
    width: 8%;
  }
  .compliance__wrapper .header-col .col-7-small-screen {
    width: 8%;
  }
}

@media (max-width: 1800px) {
  .supplier-code {
    width: 20% !important;
  }
}

@media (max-width: 1370px) {
  .supplier-code {
    width: 35% !important;
  }
  .compliance__wrapper .header-col .col-2-small-screen {
  }
  /* .compliance__wrapper .header-col .col-3-small-screen {
    width: 28% !important;
  } */

  .compliance__wrapper .header-col .col-4-small-screen {
    width: 12%;
  }
  .compliance__wrapper .header-col .col-5-small-screen {
    width: 10%;
  }
  .compliance__wrapper .header-col .col-6-small-screen {
    width: 10%;
  }
  .compliance__wrapper .header-col .col-7-small-screen {
    width: 10%;
  }
}
